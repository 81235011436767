const regexp = /^\d{0,2}?:?\d{0,2}$/u;

const isValidHour = (hour: number) => Number.isInteger(hour) && hour >= 0 && hour <= 12;
const isValidMinute = (minute: number) => Number.isInteger(minute) && minute >= 0 && minute <= 60;

export const isValid = (value: string): boolean => {
  if (!regexp.test(value)) return false;

  const parts = value.split(':');
  const hourString = parts[0] ?? '';
  const minuteString = parts[1] ?? '';
  const hours = parseInt(hourString) || 0;
  const minutes = parseInt(minuteString) || 0;

  if (!isValidHour(hours) || !isValidMinute(minutes)) return false;

  return !(minutes < 10 && Number(minuteString[0]) > 5);
};

export const getNewValue = (prevVal: string, newVal: string): string => {
  if (newVal === prevVal || !isValid(newVal)) return '';

  if (newVal.length === 2 && prevVal.length !== 3 && !newVal.includes(':')) {
    newVal = `${newVal}`;
  }

  if (newVal.length === 2 && prevVal.length === 3) {
    newVal = newVal.slice(0, 1);
  }

  if (newVal.length === 4 && !newVal.includes(':')) {
    newVal = `${newVal.slice(0, 2)}:${newVal.slice(2)}`;
  }

  return newVal;
};

export const getNewValueOnBlur = (value: string): string => {
  const inputValue = value;
  const regex = /:/g;
  const colonCount = inputValue.match(regex)?.length;
  const isLastCharacterColon = regex.test(inputValue.slice(-1));
  const colonIndex = inputValue.indexOf(':');
  let updatedValue;

  if (inputValue.length === 1) {
    if (colonCount !== 1) {
      // user input is a number (0-9)
      updatedValue = '0' + inputValue + ':00';
    } else {
      // user input is ':'
      updatedValue = '00:00';
    }
  } else if (inputValue.length === 2) {
    if (isLastCharacterColon) {
      // user input is a number (0-9) followed by a colon
      updatedValue = '0' + inputValue + '00';
    } else if (colonIndex === 0) {
      // user input is a colon followed by the number (0-5)
      updatedValue = '00:0' + inputValue.slice(-1);
    } else {
      // user input is a number (0-11)
      updatedValue = inputValue + ':00';
    }
  } else if (inputValue.length === 3) {
    if (colonIndex === 2) {
      // user input is a number (01-09 or 10-11) followed by a colon
      updatedValue = inputValue + '00';
    } else if (colonIndex === 1) {
      // user input is a number (0-9) followed by a colon and a number (0-5)
      const splittedValue = inputValue.split(':');
      updatedValue = '0' + splittedValue[0] + ':0' + splittedValue[1];
    } else if (colonIndex === 0) {
      // user input is a colon followed by a number (00-59)
      updatedValue = '00' + inputValue;
    } else {
      // user input is a number (001-012)
      updatedValue = inputValue.slice(-2) + ':00';
    }
  } else if (inputValue.length === 4) {
    if (colonIndex === 1) {
      // user input is a number (0-9) followed by a colon and a number (0-5)
      updatedValue = '0' + inputValue;
    } else if (colonIndex === 2) {
      // user input is a number (00-11) followed by a colon and a number (0-5)
      updatedValue = inputValue.slice(0, 3) + '0' + inputValue.slice(-1);
    } else {
      // user input is a 4 digit number without colon
      updatedValue = inputValue.slice(0, 2) + ':' + inputValue.slice(-2);
    }
  } else {
    updatedValue = inputValue;
  }
  return updatedValue;
};
