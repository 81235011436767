import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useAppSelector } from 'hooks/redux';
import _map from 'lodash/map';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { resetBulkEdits, selectBulkEdit } from 'store/bulkEdit/bulkEditSlice';
import { closeModal, openModal } from 'store/modal/modalSlice';
import { AssignValueType, AssignValueTypes, BulkEditTaskParams } from 'store/tasks/task.types';
import { useTasksBulkUpdateMutation } from 'store/tasks/tasksSlice';

import { AssignFormProps } from './assignTasks.types';
import AssignmentTypeSelector from './components/AssignmentTypeSelector';
import { RoleType } from './components/RoleSelector/roleSelector.types';
import UpdateFailedModal from './components/UpdateFailedModal';

const AssignTasks: React.FC = () => {
  const dispatch = useDispatch();
  const { bulkEdits } = useAppSelector(selectBulkEdit);
  const [tasksBulkUpdate, { isLoading }] = useTasksBulkUpdateMutation({
    fixedCacheKey: 'taskBulkEdit'
  });

  const methods = useForm<AssignFormProps>({
    reValidateMode: 'onChange'
  });
  const isDisabledAssignButton = isLoading || !methods.formState.isDirty;

  const handleAssign = (data: AssignFormProps) => {
    const isSelectedPrimaryProvider =
      data.type === AssignValueType.role && data?.role === RoleType.primary;

    const body: BulkEditTaskParams = {
      taskIds: bulkEdits,
      updateData: {
        operationType: isSelectedPrimaryProvider
          ? AssignValueType.primary
          : (data.type as AssignValueTypes),
        ...(data.role && !isSelectedPrimaryProvider && { audience: [data.role] }),
        ...(data.team && { teamIds: _map([data.team], 'value') }),
        ...(data.staff && { staffUserId: data.staff.id })
      }
    };

    tasksBulkUpdate(body)
      .unwrap()
      .then((data) => {
        if (data.data?.failed.length) {
          dispatch(
            openModal({
              size: 'base',
              hideClose: true,
              modalContent: <UpdateFailedModal requestBody={body} />
            })
          );
        } else {
          notifySuccess(data.message);
          dispatch(resetBulkEdits());
          dispatch(closeModal());
        }
      });
  };

  return (
    <>
      <Loader isVisible={false} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleAssign)} id="assign-tasks-form">
          <h2 data-testid="header" className="mb-2 text-xl font-bold text-gray-700">
            Assign tasks
          </h2>
          <p className="text-base font-medium text-gray-700">
            Select whom to assign the selected {bulkEdits.length} tasks
          </p>

          <AssignmentTypeSelector />

          <div className="flex gap-2">
            <Common.Button
              onClick={() => dispatch(closeModal())}
              color="white-alt"
              size="sm"
              className="!w-full justify-center"
              type="button"
            >
              Cancel
            </Common.Button>
            <Common.Button
              color="blue"
              size="sm"
              className="!w-full justify-center"
              disabled={isDisabledAssignButton}
            >
              Assign
            </Common.Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default AssignTasks;
