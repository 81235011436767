import { useEffect } from 'react';

import * as Popover from '@radix-ui/react-popover';
import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system';
import EventIcon from 'components/appointments/ManageAvailability/components/EventIcon';
import ManageAvailability from 'components/modals/ManageAvailability';
import DeleteShift from 'components/modals/ManageAvailability/DeleteEvent';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import isEmpty from 'lodash/isEmpty';
import { setEventId, setEventInstanceId, useLazyGetEventQuery } from 'store/calendar/calendarSlice';
import { openModal, selectModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';
import { getEventTypeBadge } from 'utils/calendar';

import { getDefaultTab, getRepeats } from './eventDetails.settings';
import { EventDetailsProps } from './eventDetails.types';

const eventDetailsSelector = createSelector([selectUser, selectModal], (user, modal) => ({
  isOpenModal: modal.isOpen,
  userType: user.userType
}));

const EventDetails: React.FC<EventDetailsProps> = ({
  shiftType,
  title,
  isOpen,
  eventTypes,
  recurringEventId,
  collisionBoundary,
  eventInstanceId,
  eventStartTime
}) => {
  const { userType, isOpenModal } = useAppSelector(eventDetailsSelector);

  const dispatch = useAppDispatch();
  const [getEvent, { data: recurringEventInfo, isLoading }] = useLazyGetEventQuery();

  const isTimeOff = shiftType.toLowerCase() === 'day off' || shiftType.toLowerCase() === 'break';
  const eventType = isTimeOff ? 'time-off' : 'shift';

  // Providers should not be able to add/edit/delete shifts only future time offs or breaks
  const showEditShiftButtons = userType.shortCode === 'AD';

  const showEventBadges = eventTypes && !isEmpty(eventTypes);

  /**
   * @description
   * When the fullcalendar popover is open make event details popover
   * it's child element to allow user interact with event details popover
   */
  const popoverBody = document.getElementsByClassName('fc-popover-body')[0]
    ? (document.getElementsByClassName('fc-popover-body')[0] as HTMLElement)
    : undefined;

  const handleDeleteClick = () => {
    dispatch(
      openModal({
        modalContent: (
          <DeleteShift
            eventId={eventInstanceId}
            title={title}
            recurringEventTitle={getRepeats(recurringEventInfo)}
            eventStartTime={eventStartTime}
            type={eventType}
          />
        ),
        size: 'sm',
        hideClose: true
      })
    );
  };

  const handleEditClick = () => {
    const defaultTab = getDefaultTab(shiftType);

    if (recurringEventId) {
      dispatch(setEventId(recurringEventId));
    }

    if (eventInstanceId) {
      dispatch(setEventInstanceId(eventInstanceId));
    }

    dispatch(
      openModal({
        modalContent: <ManageAvailability defaultTab={defaultTab} />,
        size: 'xl',
        hideClose: true
      })
    );
  };

  /**
   * @description
   * If modal is open keep popover open as well
   */
  const handleInteractOutside = (event: CustomEvent) => {
    if (isOpenModal) event.preventDefault();
  };

  useEffect(() => {
    if (isOpen && recurringEventId) {
      getEvent({ eventId: recurringEventId });
    }
  }, [getEvent, isOpen, recurringEventId]);

  return (
    <Popover.Portal container={popoverBody}>
      <Popover.Content
        className="z-30 w-[400px] rounded-2xl bg-white p-6 shadow-xl"
        data-testid="event-details-popover-content"
        collisionBoundary={collisionBoundary}
        onInteractOutside={handleInteractOutside}
      >
        <div className="mb-3 flex items-center justify-between">
          <div className="flex items-center gap-2">
            <EventIcon title={shiftType} />
            <h3 className="text-xl font-bold">{title}</h3>
          </div>
          {showEditShiftButtons && (
            <div className="flex items-center gap-4" data-testid="manage-event-buttons">
              <button data-testid="edit_btn" onClick={handleEditClick}>
                <Common.Icon name="pencil" />
              </button>
              <button data-testid="trash_btn" type="button" onClick={handleDeleteClick}>
                <Common.Icon name="trash" />
              </button>
            </div>
          )}
        </div>
        {isLoading ? (
          <p className="h-4 animate-pulse bg-slate-200" />
        ) : (
          <p className="whitespace-normal text-base font-medium">
            {getRepeats(recurringEventInfo)}
          </p>
        )}
        <div className="mb-6 mt-4">
          <p className="mb-2 text-base font-semibold">Types of appointments taking during shift</p>
          <div className="flex flex-wrap gap-2">
            {showEventBadges &&
              getEventTypeBadge(eventTypes).map((badge, index) => (
                <div
                  className={`flex w-fit items-center gap-1 rounded ${badge?.bgColor} px-2 py-1 text-sm ${badge?.textColor}`}
                  key={index}
                >
                  <Common.Icon name="close" className="size-3" />
                  <span data-testid="event_type" className="inline-block">
                    {badge?.title}
                  </span>
                </div>
              ))}
          </div>
        </div>

        <Popover.Close className="w-full justify-center rounded-lg bg-primary px-4 py-2 text-sm font-bold text-white">
          Close
        </Popover.Close>
      </Popover.Content>
    </Popover.Portal>
  );
};

export default EventDetails;
