import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import Loader from 'components/common/Loader';
import { useAppDispatch } from 'hooks/redux';
import { closeModal } from 'store/modal/modalSlice';

import { ContainerProps } from './container.types';

const Container: React.FC<ContainerProps> = ({
  failedCount,
  allCount,
  failedComponent,
  updatedComponent,
  handleOnRetry,
  isLoading,
  isTask,
  closeButtonTitle
}) => {
  const dispatch = useAppDispatch();

  const isAllFailed = failedCount === allCount;

  const elementTitle = isTask ? 'tasks' : 'items';
  return (
    <>
      <Loader isVisible={isLoading} />
      <div data-testid="update_failed_popup" className="p-6">
        <p data-testid="header" className="mb-4 text-xl font-bold">
          Update failed {!isAllFailed && `for ${failedCount} of ${allCount} ${elementTitle}`}
        </p>
        <p data-testid="description" className="text-base font-medium text-gray-700">
          We were unable to update{' '}
          {isAllFailed ? `these ${elementTitle}` : `the following ${elementTitle}:`}
        </p>
        {!isAllFailed && failedComponent}
        {!isAllFailed && (
          <>
            <p className="text-base font-medium text-gray-700">Successfully updated:</p>
            {updatedComponent}
          </>
        )}
        <div className="mt-4 flex gap-2">
          <Common.Button
            size="sm"
            color="white-alt"
            onClick={() => dispatch(closeModal())}
            className="!w-full justify-center"
          >
            {closeButtonTitle || 'Close window'}
          </Common.Button>

          <Common.Button
            color="blue"
            size="sm"
            onClick={handleOnRetry}
            className="!w-full justify-center"
            disabled={isLoading}
          >
            Retry
          </Common.Button>
        </div>
      </div>
    </>
  );
};

export default Container;
