import Lightbox from 'yet-another-react-lightbox';
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import 'yet-another-react-lightbox/styles.css';
import { ImageGalleryProps } from './imageGallery.types';

const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  isOpen,
  setIsOpen,
  photoIndex,
  setPhotoIndex,
  disableShowChangeImageButton
}) => {
  const mappedImages = images.map((image) => ({ src: image, width: 600, height: 640 }));

  return (
    <Lightbox
      controller={{ closeOnBackdropClick: true }}
      on={{ view: ({ index: currentIndex }) => setPhotoIndex(currentIndex) }}
      zoom={{ maxZoomPixelRatio: 4 }}
      styles={{ container: { backgroundColor: 'rgba(0, 0, 0, .85)' } }}
      index={photoIndex}
      open={isOpen}
      close={() => setIsOpen(false)}
      slides={mappedImages}
      plugins={[Fullscreen, Zoom]}
      render={{
        buttonPrev: disableShowChangeImageButton ? () => null : undefined,
        buttonNext: disableShowChangeImageButton ? () => null : undefined
      }}
    />
  );
};

export default ImageGallery;
