import classNames from 'classnames';
import { PlanRecommendationStatus } from 'enums/planRecommendation';
import { PatientPlanRecommendation } from 'store/patients/patients.types';

export const getRecommendationPlansStatus = (
  planRecommendations?: PatientPlanRecommendation[],
  hasActivePlanRecommendation?: boolean
) => {
  if (!planRecommendations?.length) return null;

  if (
    planRecommendations?.some(
      (plan) => plan.status === PlanRecommendationStatus.ACCEPTED || hasActivePlanRecommendation
    )
  ) {
    return PlanRecommendationStatus.ACCEPTED;
  }

  if (planRecommendations?.every((plan) => plan.status === PlanRecommendationStatus.PENDING)) {
    return PlanRecommendationStatus.PENDING;
  }

  return null;
};

export const getTitleAndDescription = (
  planRecommendations?: PatientPlanRecommendation[],
  hasActivePlanRecommendation?: boolean
) => {
  const planRecommendationStatus = getRecommendationPlansStatus(
    planRecommendations,
    hasActivePlanRecommendation
  );

  switch (planRecommendationStatus) {
    case PlanRecommendationStatus.ACCEPTED:
      return {
        title: 'Recommend other GLP-1 option',
        description: '',
        showCurrentMedication: true,
        buttonName: 'Update',
        selectPlanLabel: 'Please select a different option to recommend changing GLP-1'
      };
    case PlanRecommendationStatus.PENDING:
      return {
        title: 'Edit maintenance recommendation',
        description: 'Change or disable your recommendation',
        showCurrentMedication: true,
        buttonName: 'Update',
        selectPlanLabel: 'Please select a different option to edit the recommendation'
      };
    default:
      return {
        title: 'Recommend to maintenance plan',
        description:
          'This will allow the patient to opt into the maintenance plan with the following low-dose GLP-1 recommendation',
        buttonName: 'Recommend',
        selectPlanLabel: 'Please select one to proceed'
      };
  }
};

export const getRecommendPlanLabel = (
  title: string,
  description: string,
  isDisableRecommendation?: boolean
) => {
  return (
    <span className="ml-1 flex flex-col gap-0.5">
      <span className={classNames(isDisableRecommendation && 'text-red-600')}>
        {isDisableRecommendation ? 'Disable recommendation' : title}
      </span>
      <span className="text-sm font-medium text-gray">{description}</span>
    </span>
  );
};

export const getDisablePlanLabel = () => {
  return (
    <span className="ml-1 flex flex-col gap-0.5">
      <span className="text-red-600">Disable recommendations</span>
      <span className="text-sm font-medium text-gray">All recommendations will be disabled</span>
    </span>
  );
};

export const mockPlanRecommendations: PatientPlanRecommendation[] = [
  {
    status: PlanRecommendationStatus.ACCEPTED,
    medicationName: 'Medication A',
    planRecommendationId: 'plan recommendation 1',
    providerName: 'Provider A',
    providerId: '1'
  },
  {
    status: PlanRecommendationStatus.PENDING,
    medicationName: 'Medication B',
    planRecommendationId: '2',
    providerName: 'Provider B',
    providerId: '2'
  },
  {
    status: PlanRecommendationStatus.DECLINED,
    medicationName: 'Medication C',
    planRecommendationId: '3',
    providerName: 'Provider C',
    providerId: '3'
  }
];
