import React from 'react';

import { Common } from '@thecvlb/design-system';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';

import { DocumentInfoTypes } from './documentInfo.types';

const DocumentInfo: React.FC<DocumentInfoTypes> = ({
  title,
  date,
  uploadedByName,
  byCurrentUser,
  handleClick,
  tags
}) => {
  return (
    <div
      className={classNames('flex flex-1 items-center gap-3 py-4', {
        'cursor-pointer': !!handleClick
      })}
      onClick={handleClick}
    >
      <Common.Icon
        name="articles"
        className="size-[42px] rounded-lg bg-primary-50 p-[9px] text-primary"
      />
      <div className="flex flex-1 flex-col overflow-hidden">
        <p className="overflow-hidden text-ellipsis text-base font-semibold text-gray-700">
          {title}
        </p>

        <div className="mt-1 flex flex-wrap items-center">
          {tags?.map((tag) => (
            <div
              key={tag._id}
              className="mr-1.5 flex items-center whitespace-nowrap rounded bg-gray-800/5 px-1 py-0.5 text-xs font-semibold text-gray-600"
            >
              {tag.name}
            </div>
          ))}

          <p data-testid="uploaded_by" className="text-sm font-medium text-gray">
            Created by {uploadedByName && !byCurrentUser ? uploadedByName : 'me'}&nbsp;
          </p>
          <p data-testid="uploaded_when" className="text-sm font-medium text-gray">
            {date && dayjs(date).format(`• ${DateFormat.MMM_DD_YYYY}`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DocumentInfo;
