import { useCallback, useEffect, useMemo } from 'react';

import { Common } from '@thecvlb/design-system';
import isEmpty from 'lodash/isEmpty';
import { EventMultiSelectValue } from 'models/event.types';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { MultiValue, SingleValue } from 'react-select';
import { useToggle } from 'react-use';
import { useGetShiftTypesQuery } from 'store/calendar/calendarSlice';

import {
  INCOMPATIBLE_WITH_KROGER_SERVICES,
  KROGER_SERVICE_ID,
  transformShiftTypesToCategoryFilters
} from './eventCategorySelect.settings';
import {
  CategoryFilterOption,
  type CategoryFiltersProps,
  EventCategoryFieldNames,
  type EventCategoryNameType
} from './eventCategorySelect.types';
import { FormValues } from '../../manageAvailability.types';

const EventCategorySelect = ({ name }: CategoryFiltersProps) => {
  const { watch, control, clearErrors, setValue } = useFormContext<FormValues>();
  const [showCategoryMultiSelect, toggle] = useToggle(!isEmpty(watch(name)));

  const { data: shiftTypes } = useGetShiftTypesQuery();

  const selectedEventTypes = watch('eventTypes');
  const selectedCategoryFilters = watch(EventCategoryFieldNames.FILTERS);
  const isExclusions = useMemo(() => name === EventCategoryFieldNames.EXCLUSIONS, [name]);
  const oppositeValues = watch(
    isExclusions ? EventCategoryFieldNames.FILTERS : EventCategoryFieldNames.EXCLUSIONS
  );

  const shiftCategoryOptions = useMemo(() => {
    if (shiftTypes) {
      return transformShiftTypesToCategoryFilters(shiftTypes, selectedEventTypes, oppositeValues);
    }
    return [];
  }, [shiftTypes, selectedEventTypes, oppositeValues]);

  const handleChange = useCallback(
    (
      selectedOptions: MultiValue<EventMultiSelectValue> | SingleValue<EventMultiSelectValue>,
      field: ControllerRenderProps<FormValues, EventCategoryNameType>,
      lastSelectedOption: EventMultiSelectValue
    ) => {
      let newSelectedOptions = selectedOptions
        ? 'length' in selectedOptions
          ? [...selectedOptions]
          : [selectedOptions]
        : [];
      if (Array.isArray(selectedOptions) && selectedOptions.length === 0) {
        toggle(false);
      }

      const shiftCategories = shiftCategoryOptions.reduce(
        (categoryOptions, categoryOption) => categoryOptions.concat(categoryOption.options),
        [] as CategoryFilterOption[]
      );
      const exclusions = watch(EventCategoryFieldNames.EXCLUSIONS) || [];

      // NOTE: Kroger filter can’t be selected along with Optavia or HRT or Nutrition/Sweat filter
      if (lastSelectedOption && name === EventCategoryFieldNames.FILTERS) {
        if (INCOMPATIBLE_WITH_KROGER_SERVICES.includes(lastSelectedOption.value)) {
          newSelectedOptions = newSelectedOptions.filter(
            (option) => option.value !== KROGER_SERVICE_ID
          );
          const newExclusion = shiftCategories.find((option) => option.value === KROGER_SERVICE_ID);

          if (
            newExclusion &&
            !exclusions.some((exclusion) => exclusion.value === newExclusion.value)
          ) {
            setValue(EventCategoryFieldNames.EXCLUSIONS, [...exclusions, newExclusion]);
          }
        } else if (lastSelectedOption.value === KROGER_SERVICE_ID) {
          newSelectedOptions = newSelectedOptions.filter(
            (option) => !INCOMPATIBLE_WITH_KROGER_SERVICES.includes(option.value)
          );
          const newExclusions = shiftCategories.filter(
            (option) =>
              INCOMPATIBLE_WITH_KROGER_SERVICES.includes(option.value) &&
              !exclusions.some((exclusion) => exclusion.value === option.value)
          );
          if (newExclusions?.length) {
            setValue(EventCategoryFieldNames.EXCLUSIONS, [...exclusions, ...newExclusions]);
          }
        }
      }
      field.onChange(newSelectedOptions);
      clearErrors('eventTypes');
    },
    [clearErrors, toggle]
  );

  useEffect(() => {
    if (!isEmpty(selectedCategoryFilters)) toggle(true);
  }, [selectedCategoryFilters, toggle]);

  return (
    <div
      data-testid="category-filters"
      className={showCategoryMultiSelect ? 'w-full flex-none' : ''}
    >
      {showCategoryMultiSelect ? (
        <div>
          <span className="mb-2 inline-block text-base font-semibold">
            {isExclusions ? 'Add exclusions' : 'Add filters to chosen categories'}
          </span>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Common.MultiSelect
                size="sm"
                value={field.value}
                options={shiftCategoryOptions}
                placeholder={isExclusions ? 'Select exclusions' : 'Select category filter'}
                name={field.name}
                onChange={(selectedOptions, { option }) => {
                  handleChange(
                    selectedOptions as
                      | MultiValue<EventMultiSelectValue>
                      | SingleValue<EventMultiSelectValue>,
                    field,
                    option as EventMultiSelectValue
                  );
                }}
              />
            )}
          />
        </div>
      ) : (
        <Common.Button type="button" color="white" size="sm" onClick={toggle}>
          {isExclusions ? 'Add exclusions' : 'Add filter'}
        </Common.Button>
      )}
    </div>
  );
};

export default EventCategorySelect;
