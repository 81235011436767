import React from 'react';

import { Common } from '@thecvlb/design-system/lib/src';
import { notifySuccess } from 'components/common/Toast/Toast';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/modal/modalSlice';
import { useDeleteTeamMutation } from 'store/teams/teamsSlice';

const DeleteTeam: React.FC<{
  id: string;
  name: string;
}> = ({ id, name }) => {
  const dispatch = useDispatch();
  const [deleteTeam, { isLoading }] = useDeleteTeamMutation();

  const handleSubmit = () => {
    deleteTeam({ id })
      .unwrap()
      .then((data) => {
        data?.message && notifySuccess(data.message);
        dispatch(closeModal());
      });
  };

  return (
    <div data-testid="delete_team_popup" className="p-6">
      <h2 className="mb-5 text-xl font-bold text-gray-700">Delete ‘{name}’</h2>
      <p className="mb-5 text-base font-medium">
        Are you sure you would like to delete <b>{name}</b>?
      </p>
      <p className="text-base font-medium">
        Deleting <b>{name}</b> will remove all staff associated to the team as well as un-assign all
        tasks assigned to that team.
      </p>
      <div className="mt-6 flex gap-2">
        <Common.Button
          dataTestId="cancel_btn"
          onClick={() => dispatch(closeModal())}
          size="sm"
          color="white-alt"
          className="w-full justify-center"
        >
          Cancel
        </Common.Button>
        <Common.Button
          dataTestId="confirm_btn"
          size="sm"
          color="red"
          className="w-full justify-center"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          Confirm
        </Common.Button>
      </div>
    </div>
  );
};

export default DeleteTeam;
