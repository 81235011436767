import dayjs from 'dayjs';
import { DateFormat } from 'enums/dateFormats';
import { EventTypes } from 'models/event.types';

import { getDateTime } from '../manageAvailability.settings';
import { FormValues } from '../manageAvailability.types';

export const getDifferentTimezoneHours = (calendarTimezone: string, providerTimezone: string) => {
  const calendarTime = dayjs().tz(calendarTimezone).format(DateFormat.YYYY_MM_DD_HH_mm_ss);
  const providerTime = dayjs().tz(providerTimezone).format(DateFormat.YYYY_MM_DD_HH_mm_ss);
  return dayjs(calendarTime).diff(dayjs(providerTime), 'hours');
};

export const createRequestBody = (
  data: FormValues,
  startDay: Date,
  event: { start: string; end: string },
  calendarTimezone: string,
  userId: string,
  providerTimezone: string
) => {
  const isDayOff = data.eventTypes.find((type) => type.value === EventTypes.TIME_OFF);
  const dayOffTimeZone = providerTimezone ? providerTimezone : calendarTimezone;

  return {
    start: {
      dateTime: isDayOff
        ? dayjs(startDay).startOf(DateFormat.D).format(DateFormat.YYYY_MM_DD_HH_mm_ss)
        : getDateTime(startDay, event.start, DateFormat.YYYY_MM_DD_HH_mm_ss),
      timeZone: isDayOff ? dayOffTimeZone : calendarTimezone
    },
    end: {
      dateTime: isDayOff
        ? dayjs(startDay).add(1, 'day').startOf(DateFormat.D).format(DateFormat.YYYY_MM_DD_HH_mm_ss)
        : getDateTime(startDay, event.end, DateFormat.YYYY_MM_DD_HH_mm_ss),
      timeZone: isDayOff ? dayOffTimeZone : calendarTimezone
    },
    type: data.eventTypes[0].value === EventTypes.BREAK ? EventTypes.BREAK : EventTypes.TIME_OFF,
    participants: [
      {
        userId
      }
    ]
  };
};
