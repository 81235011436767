export enum TaskCategories {
  Review = 'Review',
  Appointment = 'Appointment',
  AppointmentMissed = 'Appointment • Missed',
  PostConsult = 'Post-consult',
  PreConsult = 'Pre-consult',
  Onboarding = 'Onboarding',
  FrontDesk = 'Front desk',
  Requests = 'Requests',
  RequestsPA = 'Requests • PA',
  History = 'History',
  Queue = 'Queue',
  Renewal = 'Renewal',
  Async = 'Async',
  Messages = 'Messages'
}
export enum QueueTaskStatuses {
  MIF_INCOMPLETE = 'MIF_INCOMPLETE',
  SC_INCOMPLETE = 'SC_INCOMPLETE',
  SC_SKIPPED = 'SC_SKIPPED',
  SC_COMPLETE = 'SC_COMPLETE',
  ONBOARDED = 'ONBOARDED',
  WITH_PROVIDER = 'WITH_PROVIDER',
  SC_URGENT = 'SC_URGENT',
  NO_PROVIDER_COVERAGE = 'NO_PROVIDER_COVERAGE'
}

export enum QueueTaskStatusLabels {
  MIF_INCOMPLETE = 'MIF incomplete',
  SC_INCOMPLETE = 'Symptom checker incomplete',
  SC_SKIPPED = 'Symptom checker skipped',
  SC_COMPLETE = 'Symptom checker complete',
  ONBOARDED = 'Onboarding marked complete by MA',
  WITH_PROVIDER = 'With provider',
  SC_URGENT = 'Symptom checker urgent',
  NO_PROVIDER_COVERAGE = 'No provider coverage'
}

export enum TaskSubCategories {
  Queue = 'QUEUE'
}

export enum TaskTags {
  WM = 'weight-management',
  FirstAppointment = 'first-appointment',
  Renewal = 'renewal',
  CheckInFollowUp = 'check-in-follow-up',
  MonthlyMedicationCheckIn = 'monthly-medication-check-in',
  PriorAuthorization = 'pa-required',
  LabResults = 'lab-results',
  PAReview = 'pa-review',
  Misc = 'miscellaneous',
  Documents = 'documents',
  Symptoms = 'symptoms',
  AsyncCancelAccount = 'async-cancel-account',
  MedicallyInsured = 'medically-insured',
  TripleTherapy = 'triple-therapy',
  Nutrition = 'nutrition',
  SMS = 'sms',
  Sweat = 'sweat-6s',
  Sleep = 'sleep-6s',
  Stress = 'stress-6s',
  Stillness = 'stillness-6s',
  Social = 'social-6s'
}

export enum SymptomCheckerStatus {
  SC_INCOMPLETE = 'SC_INCOMPLETE',
  SC_SKIPPED = 'SC_SKIPPED',
  SC_COMPLETE = 'SC_COMPLETE',
  SC_URGENT = 'SC_URGENT'
}
