import { IMAGE_EXTENSIONS } from 'constants/extensions';

import { DocumentsDataItemProps } from './documents.types';

export const TABS_DOCUMENTS = [
  { label: 'All' },
  { label: 'Shared by care team', value: 'uploaded_by_staff' },
  { label: 'Shared by patient', value: 'uploaded_by_patient' }
];

export const getFileExtension = (fileName: string) => {
  const extensionIndex = fileName?.lastIndexOf('.') as number;
  const fileExtension = fileName?.substring(extensionIndex).toLowerCase() as string;

  return fileExtension;
};

export const getImagesPathFromDocuments = (documents: DocumentsDataItemProps[]) => {
  return documents
    .filter((document) => {
      const fileExtension = getFileExtension(document?.fileName);
      return IMAGE_EXTENSIONS.includes(fileExtension) && document.filePath;
    })
    .map((document) => (document.filePath || '')?.toString());
};
