import AddDocumentTags from 'components/modals/AddDocumentTags';
import DeleteDocument from 'components/modals/DeleteDocument';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/modal/modalSlice';

import { DocumentProps } from './document.types';
import DocumentInfo from './DocumentInfo';
import MoreOptions from './MoreOptions';

const Document: React.FC<DocumentProps> = ({
  title,
  date,
  handleClick,
  handleDownload,
  byCurrentUser,
  uploadedByName,
  patientFullName,
  id,
  patientId,
  dataTestId,
  tags
}) => {
  const dispatch = useDispatch();

  const handleClickDelete = () =>
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: <DeleteDocument title={title} documentId={id} patientId={patientId} />
      })
    );

  const handleAddTags = () => {
    dispatch(
      openModal({
        size: 'sm',
        hideClose: true,
        modalContent: (
          <AddDocumentTags tags={tags} patientId={patientId} documentId={id}>
            <DocumentInfo
              title={title}
              date={date}
              uploadedByName={uploadedByName}
              byCurrentUser={byCurrentUser}
              tags={tags}
            />
          </AddDocumentTags>
        )
      })
    );
  };

  return (
    <div className="flex items-center" data-testid={dataTestId}>
      <DocumentInfo
        title={title}
        date={date}
        uploadedByName={uploadedByName}
        byCurrentUser={byCurrentUser}
        handleClick={handleClick}
        tags={tags}
      />

      <MoreOptions
        handleDelete={handleClickDelete}
        handleDownload={handleDownload}
        handleAddTags={handleAddTags}
        shouldShowDeleteItem={uploadedByName.trim() !== patientFullName.trim()}
        shouldEditTags={!!(tags && tags.length > 0)}
      />
    </div>
  );
};

export default Document;
