import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DatesSetArg, DayCellContentArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import luxonPlugin from '@fullcalendar/luxon3';
import FullCalendar from '@fullcalendar/react';
import { createSelector } from '@reduxjs/toolkit';
import Loader from 'components/common/Loader';
import ManageAvailability from 'components/modals/ManageAvailability';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { DateFormat } from 'enums/dateFormats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { EventTypes, TimeZoneOptions } from 'models/event.types';
import {
  selectCalendarTimezone,
  selectProviderTimezone,
  useGetShiftTypesQuery,
  useLazyGetStaffScheduleQuery
} from 'store/calendar/calendarSlice';
import { openModal } from 'store/modal/modalSlice';
import { selectUser } from 'store/user/userSlice';

import { getRightToolbar, mapEvents } from './monthlyCalendar.settings';
import { MonthlyCalendarWrapper } from './monthlyCalendar.styled';
import Event from '../Event';
import WeekTotal from '../WeekTotal';

dayjs.extend(utc);
dayjs.extend(timezone);

const selectMonthlyCalendarState = createSelector(
  [selectUser, selectCalendarTimezone, selectProviderTimezone],
  (user, calendarTimezone, providerTimezone) => ({
    userType: user.userType,
    calendarTimezone,
    providerTimezone
  })
);

const MonthlyCalendar: React.FC<{ staffId?: string }> = ({ staffId }) => {
  const dispatch = useAppDispatch();
  const { userType, calendarTimezone, providerTimezone } = useAppSelector(
    selectMonthlyCalendarState
  );

  const calendarWrapperRef = React.createRef<HTMLDivElement>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [constraintsElement, setConstraintsElement] = useState<HTMLDivElement | null>(null);

  const { data: shiftTypes } = useGetShiftTypesQuery();

  const [getStaffSchedule, { data, isLoading: isLoadingStaffSchedule }] =
    useLazyGetStaffScheduleQuery();

  const showWeekTotal = startDate && endDate && data?.info.totalDurationBreakdownByWeek;

  /**
   * @description Map event types to give them appropriate title and styles
   */
  const events = mapEvents(calendarTimezone, data?.data, shiftTypes, providerTimezone);

  const timeOffDates =
    events
      ?.filter(
        (event) => event.eventTypes && event.eventTypes.some((type) => type?.name === 'time-off')
      )
      .map((event) => dayjs(event.start).format(DateFormat.YYYY_MM_DD)) || [];

  const showAddShiftButton = userType.shortCode === 'AD';
  const showAddTimeOffButton = userType.shortCode === 'AD';

  const toolBarHandler = useMemo(
    () => ({
      left: 'prev title next today',
      center: '',
      right: getRightToolbar(showAddTimeOffButton, showAddShiftButton)
    }),
    [showAddShiftButton, showAddTimeOffButton]
  );

  const handleAddShift = useCallback(
    () =>
      dispatch(
        openModal({
          size: 'xl',
          hideClose: true,
          modalContent: <ManageAvailability defaultTab={EventTypes.SHIFT} />
        })
      ),
    [dispatch]
  );

  const handleAddTimeOff = useCallback(
    () =>
      dispatch(
        openModal({
          size: 'xl',
          hideClose: true,
          modalContent: <ManageAvailability defaultTab={EventTypes.TIME_OFF} />
        })
      ),
    [dispatch]
  );

  const handleDateChange = (arg: DatesSetArg) => {
    setStartDate(arg.start);
    setEndDate(arg.end);
  };

  const customButtons = useMemo(
    () => ({
      AddTimeOff: {
        text: 'Add time off',
        click: handleAddTimeOff
      },

      AddShift: {
        text: 'Add shift',
        click: handleAddShift
      }
    }),
    [handleAddShift, handleAddTimeOff]
  );

  useEffect(() => {
    if (!startDate && !endDate) return;

    const fromDate = dayjs(startDate).startOf('month').format(DateFormat.YYYY_MM_DD);
    const toDate = dayjs(endDate).endOf('month').format(DateFormat.YYYY_MM_DD);

    const isValidQueryParams =
      staffId && dayjs(fromDate).isValid() && dayjs(toDate).isValid() && calendarTimezone;

    if (isValidQueryParams)
      getStaffSchedule({ userId: staffId, fromDate, toDate, timezone: calendarTimezone });
  }, [endDate, getStaffSchedule, staffId, startDate, calendarTimezone]);

  useEffect(() => {
    if (calendarWrapperRef.current) setConstraintsElement(calendarWrapperRef.current);
  }, [calendarWrapperRef]);

  const dayCellClassNames = (info: DayCellContentArg) => {
    const currentDate = dayjs(info.date).format(DateFormat.YYYY_MM_DD);
    return timeOffDates.includes(currentDate) ? ['time-off-day'] : [];
  };

  return (
    <div data-testid="monthly_calendar" className="flex overflow-y-auto">
      <Loader isVisible={isLoadingStaffSchedule} />
      <MonthlyCalendarWrapper ref={calendarWrapperRef} showWeekTotal={!!showWeekTotal}>
        <FullCalendar
          timeZone={calendarTimezone || TimeZoneOptions.UTC}
          titleFormat={DateFormat.MMMM__YYYY}
          headerToolbar={toolBarHandler}
          customButtons={customButtons}
          datesSet={handleDateChange}
          dayMaxEvents={3}
          expandRows={true}
          fixedWeekCount={false}
          dayCellClassNames={dayCellClassNames}
          eventContent={(props) => (
            <Event
              start={props.event.extendedProps.startEventTime}
              end={props.event.extendedProps.endEventTime}
              title={props.event.title}
              eventTypes={props.event.extendedProps.eventTypes}
              recurringEventId={props.event.extendedProps.recurringEventId}
              constraintsElement={constraintsElement}
              eventInstanceId={props.event.id}
            />
          )}
          plugins={[luxonPlugin, dayGridPlugin]}
          initialView="dayGridMonth"
          events={events}
          eventDisplay="list-item"
          moreLinkClassNames="text-xs"
        />
      </MonthlyCalendarWrapper>
      {showWeekTotal && (
        <WeekTotal
          timezone={calendarTimezone}
          start={startDate}
          end={endDate}
          totalDurationBreakdownByWeek={data?.info.totalDurationBreakdownByWeek}
          calendarWrapperRef={calendarWrapperRef}
        />
      )}
    </div>
  );
};

export default MonthlyCalendar;
