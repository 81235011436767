import { EventMultiSelectValue } from 'models/event.types';
import { ShiftTypesResponseProps } from 'store/calendar/calendar.types';

type CategoryFilterOption = {
  value: string;
  label: string;
  bgColor: string;
  textColor: string;
  category: string;
};

export type CategoryFilter = {
  label: string;
  options: CategoryFilterOption[];
};

export const INCOMPATIBLE_WITH_KROGER_SERVICES = ['optavia', 'hrt'];
export const KROGER_SERVICE_ID = 'kroger';

const CATEGORY_FILTERS_COLORS: { [key: string]: { bgColor: string; textColor: string } } = {
  'Patient-related': {
    bgColor: '#F4F4EF',
    textColor: '#6C6A59'
  },
  Specialized: {
    bgColor: '#F8E5FF',
    textColor: '#8111AB'
  }
};

/**
 * Transforms an array of shift types into an array of category filters.
 *
 * Each category filter contains a label and an array of options. Each option
 * contains a value, a label, a background color, and a text color.
 *
 * @param {ShiftTypesResponseProps[]} shiftTypes - The shift types to transform.
 * @returns {CategoryFilter[]} The transformed category filters.
 */
const transformShiftTypesToCategoryFilters = (
  shiftTypes: ShiftTypesResponseProps[],
  selectedEvents?: EventMultiSelectValue[],
  oppositeSelectedCategories?: EventMultiSelectValue[]
) => {
  const categoryFilters: Record<string, CategoryFilter> = {};

  shiftTypes.forEach((shiftType) => {
    if (shiftType.filters) {
      const isSelected = selectedEvents?.some((event) => event.value === shiftType._id);

      if (isSelected) {
        shiftType.filters.forEach((filter) => {
          if (!categoryFilters[filter.category._id]) {
            categoryFilters[filter.category._id] = {
              label: filter.category.title,
              options: []
            };
          }

          const optionExists = categoryFilters[filter.category._id].options.some(
            (option) => option.value === filter._id
          );
          const optionExistsInOppositeSelectedCategories = oppositeSelectedCategories?.some(
            (category) => category.value === filter._id
          );

          if (!optionExists && !optionExistsInOppositeSelectedCategories) {
            categoryFilters[filter.category._id].options.push({
              value: filter._id,
              label: filter.title,
              bgColor: CATEGORY_FILTERS_COLORS[filter.category.title].bgColor,
              textColor: CATEGORY_FILTERS_COLORS[filter.category.title].textColor,
              category: filter.category._id
            });
          }
        });
      }
    }
  });

  return Object.values(categoryFilters);
};

export { transformShiftTypesToCategoryFilters };
