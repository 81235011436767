import { createSelector } from '@reduxjs/toolkit';
import { Common } from '@thecvlb/design-system/lib/src';
import { getPARequestStyle } from 'components/modals/TaskPopover/RequestDetails/requestDetails.settings';
import UpdatePAStatus from 'components/modals/UpdatePAStatus';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { openModal } from 'store/modal/modalSlice';
import { selectPatient } from 'store/patients/patientsSlice';
import { useRequestPAMutation } from 'store/tasks/tasksSlice';
import { selectUser } from 'store/user/userSlice';
import { hasPermission, REQUEST_PA_PERMISSIONS } from 'utils/popupsContentPermission';

import { PAStatusCellProps } from './paStatusCell.types';

const selectUserState = createSelector(selectUser, (user) => ({ userRoleInfo: user.userRoleInfo }));

const PAStatusCell: React.FC<PAStatusCellProps> = ({
  requestId,
  drugName,
  insuranceName,
  requestStatus
}) => {
  const dispatch = useAppDispatch();
  const { benefits } = useAppSelector(selectPatient);
  const { userRoleInfo } = useAppSelector(selectUserState);
  const [requestPA, { isLoading, isSuccess }] = useRequestPAMutation();

  const taskWasCreated = !!requestStatus;
  const showUpdatePAStatusBtn =
    hasPermission(REQUEST_PA_PERMISSIONS.updatePARequestStatus, userRoleInfo?.shortName) &&
    taskWasCreated;
  const showRequestPA = userRoleInfo?.editingPermissions?.includes('CREATE_REQUEST_PA_TASK');

  const handleRequestAuth = () => {
    if (benefits?.userId) {
      requestPA({
        patientUserId: benefits.userId,
        audience: 'MA',
        note: drugName,
        isUrgent: false,
        tpsBenefit: {
          request_id: requestId,
          drug_name: drugName,
          insurance_name: insuranceName
        },
        timezone: dayjs.tz.guess()
      });
    }
  };

  const handleUpdatePARequestStatus = () => {
    dispatch(
      openModal({
        modalContent: (
          <UpdatePAStatus
            requestStatus={requestStatus}
            medicationName={drugName}
            requestId={requestId}
            drugName={drugName}
            insuranceName={insuranceName}
            patientId={benefits?.userId || ''}
          />
        ),
        size: 'base',
        hideClose: true
      })
    );
  };

  return (
    <div className="flex w-fit min-w-36 justify-between gap-2">
      {requestStatus ? (
        <div className="flex w-[92px] items-center text-nowrap">
          {getPARequestStyle(requestStatus)}
        </div>
      ) : !!showRequestPA ? (
        <Common.Button
          onClick={handleRequestAuth}
          color="white-alt"
          className="whitespace-nowrap"
          size="sm"
          disabled={isLoading || isSuccess}
        >
          Request PA
        </Common.Button>
      ) : (
        '--'
      )}
      {showUpdatePAStatusBtn && (
        <Common.Button
          onClick={handleUpdatePARequestStatus}
          size="sm"
          color="white"
          preIcon="pencil"
          className="!px-1.5"
        />
      )}
    </div>
  );
};

export default PAStatusCell;
