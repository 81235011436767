import { RefObject } from 'react';

import { PlanCodesProps } from 'enums/appointmentStatus';
import { CrossSellLinks } from 'enums/crossSell';

export const handleActiveLink = (
  link: string,
  refs: { [key: string]: RefObject<HTMLHeadingElement> }
) => {
  refs[link]?.current?.scrollIntoView({ behavior: 'smooth' });
};

export const tabLinks = [
  CrossSellLinks.CreateProfile,
  CrossSellLinks.PlanType,
  CrossSellLinks.ConfirmAndPay
];

export const defaultCoupon = { label: 'None', value: '' };

export const getCouponCodes = (
  planCode: PlanCodesProps | undefined,
  pricePointId: string | undefined
) => {
  if (planCode === PlanCodesProps.WeightManagementMembership) {
    switch (pricePointId) {
      case 'wm-one-month-129':
        return;
      case 'wm-three-months-129':
        return [{ label: 'GLP250OFF - 25% OFF', value: 'GLP250OFF' }];
      case 'wm-six-months-129':
        return [{ label: 'GLP150OFF - 50% OFF', value: 'GLP150OFF' }];
      case 'wm-twelve-months-129':
        return [{ label: 'GLP150OFF - 50% OFF', value: 'GLP150OFF' }];

      case 'bundle-semaglutide-one-month':
        return [
          { label: '100OFFSEM - $100 OFF', value: '100OFFSEM' },
          { label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }
        ];
      case 'bundle-semaglutide-three-month':
        return [
          { label: '100OFFSEM - $100 OFF', value: '100OFFSEM' },
          { label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }
        ];
      case 'bundle-semaglutide-six-month':
        return [
          { label: '100OFFSEM - $100 OFF', value: '100OFFSEM' },
          { label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }
        ];

      case 'bundle-tirzepatide-one-month':
        return [{ label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }];
      case 'bundle-tirzepatide-three-month':
        return [{ label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }];
      case 'bundle-tirzepatide-six-month':
        return [{ label: '200OFFBUNDLE - $200 OFF', value: '200OFFBUNDLE' }];

      case 'wm-one-month-149':
        return [{ label: '74OFF1MO - $74 OFF', value: '74OFF1MO' }];
      case 'wm-three-months-447':
        return [{ label: 'GLP125REC - 25% OFF', value: 'GLP125REC' }];
      case 'wm-six-months-894':
        return [{ label: 'GLP150REC - 50% OFF', value: 'GLP150REC' }];

      case 'bundle-semaglutide-one-month-399':
        return [{ label: 'GLP125REC - 25% OFF', value: 'GLP125REC' }];
      case 'bundle-semaglutide-three-months-1197':
        return [{ label: 'GLP133REC - 33% OFF', value: 'GLP133REC' }];
      case 'bundle-semaglutide-six-months-2394':
        return [{ label: 'GLP150REC - 50% OFF', value: 'GLP150REC' }];

      case 'benefits-pa-check-intro-offer-monthly':
        return;
      default:
        return;
    }
  } else {
    return [
      { label: '4HEALTH25 - 25% OFF', value: '4health25' },
      { label: '4HEALTH50 - 50% OFF', value: '4health50' },
      { label: 'XSELL100OFF - 100% OFF', value: 'xsell100off' },
      { label: 'CAFIRE - 100% OFF', value: 'CAFIRE' }
    ];
  }
};
